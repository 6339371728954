import { getLocalStorageItem } from "../Utils";

export const getHeaders = (requireAuth=true) => {
  let headers = {
    'Content-Type' : 'application/json',
  };
  if (requireAuth) {
    const accessToken  = `Bearer ${getLocalStorageItem("access_token")}`;
    headers.Authorization = `${accessToken}`;
  }
  return headers;
};

export const Get = (service,url,auth) =>
  service.get(url, { headers: getHeaders(auth) });

export const Post = (service,url, data,auth) =>
  service.post(url, data, { headers: getHeaders(auth)});

export const Patch = (service,url, data) =>
  service.patch(url, data, { headers: getHeaders() });

  export const Put = (service,url, data) =>
  service.put(url, data, { headers: getHeaders() }); 
  
export const Delete = (service,url) =>
  service.delete(url, { headers: getHeaders() });

export const getAPIUrl = (url, params = "") => {
  return url + `${params}`;
};
export const FormDataPost=(service,url,data)=>{
  return service.post(url, data, { headers: {
    "Content-Type": "multipart/form-data",
  } }); 

}
export const FormDataPatch=(service,url,data)=>{
  return service.patch(url, data, { headers: {
    "Content-Type": "multipart/form-data",
  } }); 

}
export const getErrors = (error) => {
  const errorData = error.response.data.error;
  const errors = {};
  Object.keys(errorData).forEach((key) => {
    errors[key] = errorData[key];
  });
  return errors;
};
