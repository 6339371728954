import { ToastContainer, toast } from "react-toastify";
import dayjs from "dayjs";

export const PortalName = "Equity2Comodity";
// Get an item from local storage
export const getLocalStorageItem = (key) => {
  try {
    const item = localStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  } catch (error) {
    console.error(`Error getting ${key} from local storage: ${error}`);
    return null;
  }
};

// Set an item in local storage
export const setLocalStorageItem = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch (error) {
    console.error(`Error setting ${key} in local storage: ${error}`);
  }
};

// Remove an item from local storage
export const removeLocalStorageItem = (key) => {
  try {
    localStorage.removeItem(key);
  } catch (error) {
    console.error(`Error removing ${key} from local storage: ${error}`);
  }
};

//antd custom message function
export const messageConfiguration = (type, message, duration) => {
  return {
    type: type,
    content: message,
    style: { marginTop: "80px" },
    duration: duration,
  };
};

// custom react-toastify
export const dispatchtoast = (message, type) => {
  if (type) {
    return toast.error(message);
  }
  return toast.success(message);
};

// dynamic function to catch all errors and show on react toastify
export const showError = (error) => {
  if (typeof error === "object") {
    Object.entries(error).map(([key, value]) => {
      const errmessage = `${key}: ${value}`;

      return dispatchtoast(errmessage, true);
    });
  } else {
    return dispatchtoast(error, true);
  }
};

// patterns for pin,aadhar,pan,email,mobile,gst
export const pincodePattern = /^[1-9][0-9]{5}$/; // Regular expression for valid PIN code format
export const aadharPattern = /^\d{12}$/; // Regular expression for valid Aadhaar card number format
export const panPattern = /^[A-Za-z]{5}\d{4}[A-Za-z]{1}$/; // Regular expression for PAN number format
export const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/; // Regular expression for email format
export const mobilePattern = /^[6-9]\d{9}$/; // Regular expression for valid mobile number format
export const gstPattern =
  /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/; // GST number pattern
export const ifscPattern = /^[A-Z]{4}0[A-Z0-9]{6}$/;
export const pancardValidation = (text) => {
  let regex = /^([a-zA-Z]){5}([0-9]){4}([a-zA-Z]){1}?$/;
  if (regex.test(text)) {
    return true;
  }
  return false;
};
export const validateEmail = (email) => {
  const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;
  return emailRegex.test(email);
};
export const validateAadharNumber = (number) => {
  const aadharRegex = /^\d{12}$/;
  if (aadharRegex.test(number)) {
    return true;
  }
  return false;
};

// function to convert rupee in number to rupeee in words
export const numberToWords = (num) => {
  const singleDigitWords = [
    "Zero",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const twoDigitWords = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tensWords = [
    "",
    "",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  if (num < 0 || num >= 10000) {
    return "Number out of range";
  }

  if (num < 10) {
    return singleDigitWords[num] + " Only";
  }

  if (num >= 10 && num < 20) {
    return twoDigitWords[num - 10] + " Only";
  }

  if (num >= 20 && num < 100) {
    const tens = Math.floor(num / 10);
    const ones = num % 10;
    return (
      tensWords[tens] + (ones ? " " + singleDigitWords[ones] : "") + " Only"
    );
  }

  if (num >= 100 && num < 1000) {
    const hundreds = Math.floor(num / 100);
    const remaining = num % 100;
    return (
      singleDigitWords[hundreds] +
      " Hundred" +
      (remaining ? " " + numberToWords(remaining) : "")
    );
  }

  if (num >= 1000 && num < 10000) {
    const thousands = Math.floor(num / 1000);
    const remaining = num % 1000;
    return (
      singleDigitWords[thousands] +
      " Thousand" +
      (remaining ? " " + numberToWords(remaining) : "")
    );
  }
};

// function to enable search in antd select by label
export const filterOption = (input, option) =>
  option.label?.toLowerCase().indexOf(input?.toLowerCase()) >= 0;

// function to define react-toastify
export const ToastContainerMsg = () => {
  return (
    <ToastContainer
      style={{
        fontSize: "12px",
      }}
      position="top-right"
      autoClose={3000}
      hideProgressBar={false}
      newestOnTop={false}
      closeOnClick
      rtl={false}
      pauseOnFocusLoss
      // draggable
      // transition="flip"
      pauseOnHover
      theme="colored"
    />
  );
};

// function to download html into PDF format
// export const handledownloadPDF = (ref) => {
//   const doc = new jsPDF({
//     format: "a4",
//     unit: "px",
//   });
//   // Adding the fonts
//   doc.setFont("Inter-Regular", "normal");
//   doc.html(ref.current, {
//     async callback(doc) {
//       await doc.save("document");
//     },
//   });
// };

// function to get current date and time
export function getCurrentTime(date) {
  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const days = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];

  const dayOfWeek = days[date.getDay()];
  const dayOfMonth = date.getDate();
  const month = months[date.getMonth()];
  const year = date.getFullYear();
  const hours = date.getHours();
  const minutes = date.getMinutes();
  const seconds = date.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";
  const formattedHours = hours % 12 || 12;

  const currentTimeString = `${dayOfWeek}, ${dayOfMonth} ${month}, ${year} ${
    formattedHours < 10 ? 0 + "" + formattedHours : formattedHours
  }:${minutes}:${seconds} ${ampm}`;

  return currentTimeString;
}

// global function to call profile again and again

export function formatDateAndTime(dateTimeString) {
  // Parse the input date string
  const dateParts = dateTimeString.split(" ");
  const date = dateParts[0];
  const time = dateParts[1];

  const [day, month, year] = date.split("-");
  const [hour, minute] = time.split(":");
  // Create a Date object
  const parsedDate = new Date(year, month - 1, day, hour, minute);
  // Format the date in "DD-MM-YYYY hh:mm AM/PM" format
  const formattedDate = `${day}-${month}-${year} ${parsedDate.toLocaleString(
    "en-US",
    {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    }
  )}`;

  return formattedDate;
}

export const trimString = (string, limit = 25) => {
  const trim =
    string && string.length > limit
      ? string.substring(0, limit) + "..."
      : string;
  return trim;
};

export const convertDate = (date) => {
  return dayjs(date)
    .hour(0)
    .minute(0)
    .second(0)
    .millisecond(0)
    .format("YYYY-MM-DDTHH:mm:ss");
};

export const getAMPM = (hour) => {
  if (hour < 12) {
    return "AM";
  } else {
    return "PM";
  }
};

export const makeLabel = (name) => {
  return (
    <p
      style={{ padding: "0px" }}
      className="font-poppins p-0 text-xs text-black"
    >
      {name}
    </p>
  );
};
export const CalculateAverageRating = (rating) => {
  return (
    Object.values(rating).reduce((a, b) => {
      return (a = a + b);
    }, 0) / Object.keys(rating)?.length
  );
};
