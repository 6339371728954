import Signup from "../Assets/Signup.png";
import Login from "../Assets/Login.png";
import Google from "../Assets/google.png";
import facebook from "../Assets/facebook.png";
import Logo from "../Assets/Logo.png";
import FullLogo from "../Assets/FullLogo.png";
import Hamburger from "../Assets/menu.png";
import CourseImage from "../Assets/Course Images.png";
import Graph from "../Assets/Graph.png";
import OrderSuccess from "../Assets/Success.png";
import ClipBoardText from "../Assets/ClipboardText.png";
import Stack from "../Assets/Stack.png";
import MonitorPlay from "../Assets/MonitorPlay.png";
import PlayCircle from "../Assets/PlayCircle.png";
import Image from "../Assets/Image.png";
import miniPlayCircle from "../Assets/miniPlayCircle.png";
export const Images = {
  Signup,
  Login,
  Google,
  facebook,
  Logo,
  FullLogo,
  Hamburger,
  CourseImage,
  Graph,
  OrderSuccess,
  ClipBoardText,
  Stack,
  MonitorPlay,
  PlayCircle,
  Image,
  miniPlayCircle,
};
