import React, { Suspense, useState } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { Images } from "../../ImageController/Index";
import { Button, Form, Input, Spin } from "antd";
import {
  dispatchtoast,
  emailPattern,
  makeLabel,
  setLocalStorageItem,
} from "../../Utils";
import CommonInput from "../../CommonComponents/CommonInput";
import { FaArrowRight } from "react-icons/fa";
import { userRegister } from "./AuthFun";
import { NavLink, useNavigate } from "react-router-dom";

const SignUp = () => {
  const [btnLoading, setBtnLoading] = useState(false);
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const handleRegister = (val) => {
    setBtnLoading(true);
    let data = { ...val };
    userRegister(data)
      .then((res) => {
        if (res.status) {
          setLocalStorageItem("accessToken", res.token);
          setLocalStorageItem("IsLoggesIn", true);
          navigate("/dashboard");
          dispatchtoast(res.message);
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setBtnLoading(false));
  };
  return (
    <Suspense
      fallback={
        <div className="flex justify-center items-center h-screen">
          <Spin spinning={true}></Spin>
        </div>
      }
    >
      <div className="container flex justify-center items-center h-screen">
        <div className="grid grid-cols-2 place-items-center ">
          <div className="w-full h-screen bg-authBg hidden md:flex justify-center items-center ">
            <LazyLoadImage
              src={Images.Signup}
              alt="signupimage"
              className="w-[70%]"
            />
          </div>
          <div className="w-full flex justify-center items-center h-screen col-span-2 md:col-span-1">
            <div className="w-3/4  m-auto">
              <div className="font-poppins text-center text-xl font-bold">
                <p>Create your account</p>
              </div>
              <Form form={form} onFinish={(val) => handleRegister(val)}>
                <div className="grid grid-cols-2 place-items-start mt-5 gap-x-4">
                  <Form.Item
                    name={"firstName"}
                    rules={[
                      {
                        required: true,
                        message: "First Name Is Required",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label={makeLabel("First Name")}
                  >
                    <CommonInput placeHolder="First Name" />
                  </Form.Item>
                  <Form.Item
                    name={"lastName"}
                    rules={[
                      {
                        required: true,
                        message: "Last Name Is Required",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label={makeLabel("Last Name")}
                  >
                    <CommonInput placeHolder="Last Name" />
                  </Form.Item>
                </div>
                <Form.Item
                  rules={[
                    {
                      required: true,
                      message: "User Name Is Required.",
                    },
                  ]}
                  name={"username"}
                  labelCol={{ span: 24 }}
                  label={makeLabel("User Name")}
                >
                  <CommonInput placeHolder="User Name" />
                </Form.Item>
                <Form.Item
                  name={"email"}
                  rules={[
                    {
                      required: true,
                      message: "Email Is Required",
                    },
                    {
                      pattern: emailPattern,
                      message: "Please Enter Valid Email",
                    },
                  ]}
                  labelCol={{ span: 24 }}
                  label={makeLabel("Email")}
                >
                  <CommonInput placeHolder="Email" />
                </Form.Item>
                <div className="grid grid-cols-2 place-items-start gap-x-4">
                  <Form.Item
                    name={"password"}
                    rules={[
                      {
                        required: true,
                        message: "Password Is Required",
                      },
                    ]}
                    labelCol={{ span: 24 }}
                    label={makeLabel("Password")}
                  >
                    <Input.Password
                      autoComplete={false}
                      placeholder={"Password"}
                      className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    />
                  </Form.Item>{" "}
                  <Form.Item
                    name="confirmPassword"
                    // dependencies={["password"]}
                    rules={[
                      {
                        required: true,
                        message: "Confirm Password Is Required",
                      },
                      // { validator: validatePassword },
                    ]}
                    labelCol={{ span: 24 }}
                    label={makeLabel("Confirm Password")}
                  >
                    <Input.Password
                      placeholder={"Confirm Password"}
                      className="rounded-none  hover:border hover:border-gray-300 hover:shadow-none focus-within:border focus-within:border-gray-300 focus:border focus:border-gray-300"
                    />
                  </Form.Item>
                </div>
                <div className="flex justify-end items-center">
                  <Button
                    loading={btnLoading}
                    icon={<FaArrowRight />}
                    iconPosition="end"
                    type="primary"
                    htmlType="submit"
                    style={{ color: "white" }}
                    prefix="jj"
                    className="font-poppins text-white font-bold rounded-none bg-primary"
                  >
                    Create Account
                  </Button>
                </div>
              </Form>
              <NavLink to={"/"}>
                <p className="text-gray1 mt-3 cursor-pointer text-sm font-poppins text-center">
                  Already have an account? sign in
                </p>
              </NavLink>
              
            </div>
          </div>
        </div>
      </div>
    </Suspense>
  );
};

export default SignUp;
