import { lazy } from "react";
import ProtectedAuth from "./PrivateAuth";
const Dashboard = lazy(() => import("../Views/Main/Dashboard/Dashboard"));
const Courses = lazy(() => import("../Views/Main/Course/Courses"));
const CourseDetail = lazy(() => import("../Views/Main/Course/CourseDetail"));

export const PrivateAuth = () => {
  return [
    {
      element: <ProtectedAuth />,
      children: [
        {
          path: "/Dashboard",
          element: <Dashboard />,
        },
        {
          path: "/Courses",
          element: <Courses />,
        },
        {
          path: "/courseDetail/:courseId",
          element: <CourseDetail />,
        },
        
       
        // ERROR ROUTES ENDED
      ],
    },
  ];
};
