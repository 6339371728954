import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { setLocalStorageItem } from "../../Utils";
import { GoogleVerify } from "./AuthFun";
import { Spin } from "antd";

const AuthPage = () => {
  const code=new URLSearchParams(useLocation().search)
  const [loading, setLoading] = useState(false);
  const navigate=useNavigate()
  useEffect(() => {
    setLoading(true);
    GoogleVerify(`?code=${code.get("code")}`)
      .then((res) => {
        if (res.success) {
          setLocalStorageItem("accessToken", res.token);
          setLocalStorageItem("IsLoggesIn", true);
          navigate("/dashboard")
        }
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
      //eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <Spin spinning={loading}>
        <div className="flex justify-center items-center h-screen">
          <p className="text-lg font-poppins font-bold">Loading......</p>
        </div>
      </Spin>
    </>
  );
};

export default AuthPage;
