import axios from "axios";
import { dispatchtoast, removeLocalStorageItem, showError } from "../../Utils";

export const version = "api/v1/";
export const AUTH_ENDPOINT = `http://13.233.193.58:3001/${version}`;

export const AuthService = axios.create({
  // withCredentials: true,
  baseURL: AUTH_ENDPOINT,
});

const handleResponse = (response) => {
  if (response.data.status || response.data.success) {
    return response.data;
  } else if (response.data.formErrors) {
    showError(response.data.formErrors);
  } else if (response.data.validationError) {
    showError(response.data.validationError);
  } else {
    dispatchtoast(response.data.message ?? response.data.error, true);
  }
  return response.data;
};
const handleError = (error) => {
  const { status } = error?.response;
  if (status === 401) {
    dispatchtoast(
      error?.response.data.message ?? error?.response.data.msg,
      true
    );
    removeLocalStorageItem("isLoggedIn");
    removeLocalStorageItem("accessToken");
    window.location.href = "/";
  }
  if (status && status >= 400 && status < 500) {
    if (error.response.data.formErrors) {
      showError(error.response.data.formErrors);
    } else if (error.response.data.validationError) {
      showError(error.response.data.validationError);
    } else {
      dispatchtoast(
        error?.response.data.message ?? error?.response.data.error,
        true
      );
    }
  } else if (status && status >= 500) {
    dispatchtoast(
      error?.response.data.message ?? error?.response.data.msg,
      true
    );
  }
  return Promise.reject(error);
};
// Add a request interceptor
AuthService.interceptors.request.use((config) => {
  // Add the JWT token to the request headers
  // if (jwtToken) {
  //   config.headers['Authorization'] = `jwt_token=${jwtToken}`;
  // }
  // Add other headers if needed
  // config.headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});
// Add a response interceptor
AuthService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    // Handle errors if needed
    handleError(error);
  }
);

export const COURSE_ENDPOINT = `http://13.233.121.178:3003/${version}`;

export const CourseService = axios.create({
  // withCredentials: true,
  baseURL: COURSE_ENDPOINT,
});

// Add a request interceptor
CourseService.interceptors.request.use((config) => {
  // Add the JWT token to the request headers
  // if (jwtToken) {
  //   config.headers['Authorization'] = `jwt_token=${jwtToken}`;
  // }
  // Add other headers if needed
  // config.headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});
// Add a response interceptor
CourseService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    // Handle errors if needed
    handleError(error);
  }
);

export const CHAT_ENDPOINT = `http://3.109.155.147:3002/${version}`;

export const ChatService = axios.create({
  // withCredentials: true,
  baseURL: CHAT_ENDPOINT,
});

// Add a request interceptor
ChatService.interceptors.request.use((config) => {
  // Add the JWT token to the request headers
  // if (jwtToken) {
  //   config.headers['Authorization'] = `jwt_token=${jwtToken}`;
  // }
  // Add other headers if needed
  // config.headers['X-Timezone'] = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return config;
});
// Add a response interceptor
ChatService.interceptors.response.use(
  (response) => handleResponse(response),
  (error) => {
    // Handle errors if needed
    handleError(error);
  }
);
