import React, { useEffect, useState } from "react";
import { Badge, Input, Layout, Menu, Select } from "antd";
import { Images } from "../ImageController/Index";
// import { Menuitems } from "./Menuitems";
// import { persistor } from "../Store/Store";

// import { useDispatch } from "react-redux";
// import {
//     // useLocation,
//      useNavigate } from "react-router-dom";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { MenuItems } from "./MenuItems";
import { GoBell, GoHeart } from "react-icons/go";
import { CiUser } from "react-icons/ci";
import { RiSearchLine } from "react-icons/ri";

const { Header, Sider, Content } = Layout;
const ProjectLayout = ({ children }) => {
  const [collapsed, setCollapsed] = useState(false);
  const [windowwidth, setwindowwidth] = useState(window.innerWidth);
  const [showDefault, setShowDefault] = useState(false);
  
  //   const navigate = useNavigate();
  //   const dispatch = useDispatch();
  //   const location = useLocation();
  useEffect(() => {
    const handleResize = () => {
      setwindowwidth(window.innerWidth);
    };
    window.addEventListener("resize", handleResize);
    // setselectedTab(getLocalStorageItem("selectedTab"));
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  useEffect(() => {
    if (windowwidth > 1000) {
      setCollapsed(false);
      setShowDefault(true);
    } else {
      setCollapsed(true);
      setShowDefault(false);
    }
  }, [windowwidth]);

  return (
    <>
      <Layout hasSider className="min-h-screen sidebar">
        <Sider
          style={{
            position: "fixed",
            marginBottom: "20px",
            overflowY: "scroll",
            height: "100vh",
          }}
          trigger={null}
          collapsible
          className=""
          collapsed={collapsed}
        >
          <div className="demo-logo-vertical flex justify-around items-center my-5">
            {!collapsed ? (
              <>
                <div className="flex gap-4 justify-start items-center">
                  {" "}
                  <img
                    src={Images.FullLogo}
                    className=" w-[75%] ml-5"
                    alt="Weblogo"
                  />
                </div>
                {/* <img
                  onClick={() => setCollapsed(!collapsed)}
                  className="cursor-pointer"
                  src={Images.Hamburger}
                  alt="logo"
                  width={30}
                  height={30}
                /> */}
              </>
            ) : (
              <img src={Images.Logo} alt="WeblogoSmall" className="h-8 w-8" />
            )}
          </div>
          <Menu
            theme="dark"
            mode="inline"
            defaultSelectedKeys={["1"]}
            // selectedKeys={[selectedTab]}
            items={MenuItems()}
          />
        </Sider>
        <Layout>
          <Header
            style={{
              marginLeft: collapsed ? 45 : 200,
            }}
            className=" bg-white  h-24 "
          >
            <div className="grid grid-cols-1 sm:grid-cols-2  items-center m-2">
              <div className="ml-10">
                <p className="text-primary font-dmsans">Hello!</p>
                <p className="text-lg font-bold font-dmsans ">
                  Create a New Course
                </p>
              </div>
              {/* <div className="flex w-full justify-center items-center gap-x-4">
                <Select options={[]} placeholder="Browse"  className="w-[40%]  font-poppins rounded-none"/>
              </div>
              <div className="w-full">
                <Input
                  className=" font-poppins rounded-none border border-gray-300 hover:border-gray-300  focus:hover:border-gray-300 text-xs"
                  size="large"
                  placeholder="What do you want to learn...."
                  prefix={<RiSearchLine size={20} />}
                />
              </div> */}
              <div className="flex  justify-end items-center gap-x-4 mr-10">
                <p className="cursor-pointer">
                  <Badge dot>
                    <GoBell size={18} />
                  </Badge>
                </p>
                <p className="cursor-pointer">
                  <Badge count={2} size="small">
                    <GoHeart size={18} />
                  </Badge>
                </p>
                <p className="cursor-pointer">
                  <CiUser size={18} />
                </p>
                <p className="cursor-pointer">
                  <CiUser size={18} />
                </p>
              </div>
            </div>
          </Header>
          <Content
            className={`bg-white h-full p-5 md:p-8
                 ${collapsed ? "ml-[45px]" : "ml-[200px]"}
            `}
          >
            {/* <div className="mb-5 w-full flex justify-between items-center gap-4">
              <BsArrowLeft
                size={20}
                onClick={() => navigate(-1)}
                className="cursor-pointer"
              />
            </div> */}

            {children}
          </Content>
        </Layout>
      </Layout>
    </>
  );
};

export default ProjectLayout;
