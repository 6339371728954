import { BrowserRouter, useRoutes } from "react-router-dom";
import "./App.css";
import 'react-slideshow-image/dist/styles.css';
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { PrivateAuth } from "./Routes/PrivateRoutes";
import { PublicRoutes } from "./Routes/PublicRoutes";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainerMsg } from "./Utils";

function App() {
  const Routing = [...PublicRoutes(), ...PrivateAuth()];
  const RouteConfig = () => useRoutes(Routing);
  
  return (
    <>
      <BrowserRouter>
          <RouteConfig />
      </BrowserRouter>
      <ToastContainerMsg />
    </>
  );
}

export default App;
